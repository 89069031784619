<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedXmlActions"
        :default-value="properties.action"
        step-label="xml"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-sourceObject`"
        v-model="properties.sourceObject"
        outlined
        dense
        :label="$lang.labels.sourceObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="xml-targetObject"
        @change="handleChange('sourceObject', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="xml-targetObject"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect } from './components'
import StepMixin from './StepMixin'

import { XML_ACTIONS } from './constants'

export default {
  name: 'XMLStep',
  components: {
    ActionSelect
  },
  mixins: [StepMixin],
  computed: {
    formattedXmlActions() {
      return XML_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) this.properties.action = 'XML_TO_JSON'
  },
  methods: {
    handleChange(field, event) {
      let value = event

      // Ensure sourceObject is a String
      if (field === 'sourceObject') {
        value = String(event)
      }

      this.$set(this.properties, field, value)
      this.$emit('update-step', { field, value })
    }
  }
}
</script>
